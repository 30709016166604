body {
  background-color: rgba(237, 242, 247);
}

/* .tableContainer {
  background-color: #fff;
}

.tableContainer {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
} */

.text {
  font-size: .75rem;
}